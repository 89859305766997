<template>
  <vue-scroll class="register-page ps">
    <div class="flex column form-box  card-base card-shadow--large">
      <div class="center">
        <h2 class="accent-text">QR code</h2>
        <p>Please press "Download" to get this QR code on your phone save time during check-in at the venue.</p>
      </div>
      <el-divider class="mv-10 o-020"></el-divider>
      <div class="box">
        <vue-qrcode ref="qr" :options="{ width: 280 }" :value="'rolldxb: ' + id"></vue-qrcode>
      </div>
      <div class="box pt-16">
        <el-button type="primary" size="small" @click="download" class="signin-btn">
          DOWNLOAD IMAGE
        </el-button>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import { mapState } from 'vuex'
import VueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  name: 'Home',

  components: {
    VueQrcode
  },

  data () {
    return {}
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    id () {
      return this.user !== undefined && this.user !== null && this.user.hasOwnProperty('_id') ? this.user._id : undefined
    }
  },
  methods: {
    download () {
      let image = this.$refs.qr.$el.toDataURL()
      let aDownloadLink = document.createElement('a')
      aDownloadLink.download = 'rolldxb-waiver.png'
      aDownloadLink.href = image
      document.body.appendChild(aDownloadLink)
      aDownloadLink.click()
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  }
}
</script>
